<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              id="avenir_calendar"
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        :calendar-options="calendarOptions"
        @remove-event="removeEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import router from '@/router'
import { onMounted, onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  data() {
    return {
      CALENDAR_APP_STORE_MODULE_NAME: 'calendar',
      availabilities: [],
    }
  },
  async mounted() {
    const _self = this

    document.getElementsByClassName('fc-dayGridMonth-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-timeGridWeek-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-timeGridDay-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-next-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    this.changeDoctor()
  },
  methods: {
    isNewEvent() {
      return this.elementData && this.elementData.case_id && this.elementData.case_id > -1
    },
    processAvailabilities() {
      console.log('starting')
      const week_tables = document.querySelectorAll('table[role="presentation"]')
      if (week_tables) {
        for (const week_table of week_tables) {
          for (const td of week_table.rows) {
            for (const cell of td.cells) {
              cell.classList.add('fc-my-non-business')
              if (this.hasAvailability(cell.getAttribute('data-date'))) {
                cell.classList.remove('fc-my-non-business')
              }
            }
          }
        }
      }
      const month_table = document.getElementsByClassName('fc-scrollgrid-sync-table')
      if (month_table) {
        for (const td of month_table[0].rows) {
          for (const cell of td.cells) {
            cell.classList.add('fc-my-non-business')
            if (this.hasAvailability(cell.getAttribute('data-date'))) {
              cell.classList.remove('fc-my-non-business')
            }
          }
        }
      }
      console.log('finishing')
    },
    async changeDoctor() {
      const _self = this
      const newDoctor = await store
        .dispatch(`${_self.CALENDAR_APP_STORE_MODULE_NAME}/fetchCurrentUser`)
        .then(response => response.data.id).catch(err => {
          console.log(err)
          _self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching calendar events 1',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        })
      this.availabilities = await store
        .dispatch(`${_self.CALENDAR_APP_STORE_MODULE_NAME}/fetchDoctorAvailability`, { newDoctor })
        .then(response => response.data.availabilities).catch(() => {
          _self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching calendar events 1',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        })
      store.commit('calendar/SET_SELECTED_AVAILABILITIES', this.availabilities)
      this.processAvailabilities()
      store.commit('calendar/SET_SELECTED_DOCTOR', newDoctor)
      console.log('se llama fetchEvents desde changeDoctor')
      await this.updateEvents()
    },
    async updateEvents() {
      const calendarApi = this.$refs.refCalendar.getApi()
      const currentData = calendarApi.getCurrentData()
      const { start, end } = currentData.dateProfile.activeRange
      let events = await this.fetchEvents(start, end);

      console.log('Eventos originales obtenidos:', events);

      events = events.map(event => {
        let eventTypeCleaned = event.event_type.replace(/Consulta/i, '').trim();
        eventTypeCleaned = eventTypeCleaned.charAt(0).toUpperCase() + eventTypeCleaned.slice(1);

        if (event.patient_name) {
          event.title = `${eventTypeCleaned}        -         ${event.patient_name}      -    ${event.consult_status}`;
        } else {
          event.title = 'Sin información del paciente';
        }

        if (event.priority_case) {
          event.backgroundColor = '#d7c2ed';  
        }

        return event;
      });

      // Finalmente, asigna los eventos modificados
      this.calendarOptions.events = events;

      console.log('Eventos actualizados:', this.calendarOptions.events);
    }
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      removeEvent,
      fetchEvents,
      fetch_key_value,
      calendarOptions,
      setEvent,
      hasAvailability,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    onMounted(async () => {
      store.commit('calendar/SET_DATE_TYPE_LIST', await fetch_key_value('date_type'))
    })

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      removeEvent,
      fetchEvents,
      fetch_key_value,
      calendarOptions,
      setEvent,
      hasAvailability,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.fc-my-non-business {
  background: var(--fc-non-business-color, rgba(242, 220, 219, 1)) !important
}
.fc .fc-my-non-business {
  background: var(--fc-non-business-color, rgba(15, 110, 124, 0.2)) !important
}
.fc .fc-day-today {
  background: #A2D9CE !important
}
</style>
