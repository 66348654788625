<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Cita
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event');"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <b-form-group
              label="Trabajador"
            >
              {{ eventLocal.patient_name }}
            </b-form-group>

            <b-form-group
              label="No. Documento"
            >
              {{ eventLocal.patient_id }}
            </b-form-group>

            <b-form-group
              label="No. Caso"
            >
              {{ eventLocal.case_number }}
            </b-form-group>

            <b-form-group
              label="Tipo"
            >
              {{ getTypeValue(eventLocal, 'type_id') }}
            </b-form-group>

            <b-form-group
              label="Fecha de Inicio"
            >
              {{ formatDate(eventLocal, 'start_date') }}
            </b-form-group>

            <b-form-group
              label="Fecha de Fin"
            >
              {{ formatDate(eventLocal, 'end_date') }}
            </b-form-group>

            <b-form-group
              label="URL de la cita"
            >
              <span class="mt-3"><a
                :href="getUrl(eventLocal, 'url')"
                target="_blank"
              >Iniciar consulta</a></span>
            </b-form-group>

            <!-- Textarea -->
            <b-form-group
              label="Descripción"
              label-for="event-description"
            >
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.description"
                disabled
              />
            </b-form-group>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormSelect, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import DateTimePicker from '@/views/core/DateTimePicker.vue'
import moment from 'moment'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    DateTimePicker,
    BButton,
    BSidebar,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      url,
      loading: true,
    }
  },
  watch: {
    'eventLocal.start_date': function (newValue, oldValue) {
      // doesn't recalculates if is loading
      const nv = moment(newValue, 'DD/MM/YYYY h:mm A')
      const ov = moment(oldValue, 'DD/MM/YYYY h:mm A')
      if (this.loading || !oldValue || newValue == oldValue || nv.isSame(ov)) {
        this.loading = false
      } else {
        const type = this.dateTypeList.find(element => element.id === this.eventLocal.type_id)
        let mom = moment(newValue, 'DD/MM/YYYY HH:mm')
        mom = mom.add(30, 'minutes').format('DD/MM/YYYY h:mm A')
        this.eventLocal.end_date = mom
      }
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      dateTypeList,
      getTypeValue,
      formatDate,
      getUrl,

      // UI
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      dateTypeList,
      onSubmit,
      getTypeValue,
      formatDate,
      getUrl,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
