import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  const dateTypeList = computed(() => store.state.calendar.dateTypeList)

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

  }
  const getTypeValue = (element, type) => {
    if (!dateTypeList || !dateTypeList.value) {
      return ''
    }
    var tv = dateTypeList.value.find(el => el.id == element[type])
    return tv ? tv.value : ''
  }


  const formatDate = (element, field) => {
    var ret = moment(element[field], 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    return ret == 'Invalid date' || ret == 'Fecha inválida' ? '' : ret
  }

  const getUrl = (element, field) => {
    var ret = process.env.VUE_APP_BASE_API + '/meet/local/' + element[field]
    var type = dateTypeList.value.find(el => el.id == element.type_id)?.key
    if (type && (type.includes('FOLLOW') || type.includes('ADDITIONAL') || type.includes('EVOLUTION'))) {
      ret = process.env.VUE_APP_BASE_API + '/meet/local_follow/' + element[field]
    }
    return ret
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,
    dateTypeList,
    getTypeValue,
    formatDate,
    getUrl,

    // UI
    onSubmit,
  }
}
